.leaflet-control-attribution {
    display: none;
}

.leaflet-control-container>.leaflet-top,
.leaflet-right {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    z-index: 400;
}

.dashboard .leaflet-control-container>.leaflet-top {
    top: 0px !important;
}

.main .leaflet-control-container>.leaflet-top {
    top: 70px !important;
}

.space-children-horizontal>* {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
}

.space-children-horizontal>*:first-child {
    margin-left: 0 !important;
}

.space-children-horizontal>*:last-child {
    margin-right: 0 !important;
}

.leaflet-control-layers-toggle {
    background-color: white !important;
}

.leaflet-div-icon {
    background: none !important;
    border: none !important;
}

.leaflet-control-zoom-in {
    background-color: white !important;
    color: var(--primary-color) !important;
    padding: 6.5px !important;
}

.leaflet-control-zoom-out {
    background-color: white !important;
    color: var(--primary-color) !important;
    padding: 6.5px !important;
}

.leaflet-container {
    border-top-left-radius: 20px;
}