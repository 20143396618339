:root {
  --main-color: #30A6D3;
  --primary-color: #51DBA5;
  --secondary-color: #8B95AB;
  --tertiary-color: #8B95AB;
  --active-color: #000000;
  --container-color: #ffffff;
  --background-color: #f5f6f8;
  --dark-color: #2C2400;
  --light-color: #FEFEFE;
  --dark-blue-color: rgba(30, 52, 101, 1);
}