.leaflet-control-attribution{
    display: none;
}

.leaflet-control-container >
.leaflet-top,
.leaflet-right {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}
.leaflet-control-container >
.leaflet-top {
    top: 0px;
}

.space-children-horizontal > * {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
}
.space-children-horizontal > *:first-child {
    margin-left: 0 !important;
}
.space-children-horizontal > *:last-child {
    margin-right: 0 !important;
}

.leaflet-control-layers-toggle {
    background-color: var(--primary-color);
}

.leaflet-div-icon {
    background: none !important;
    border: none !important;
}

.leaflet-control-zoom-in {
    background-color: white !important;
    color: #b9b9b9 !important;
    padding: 6.5px !important;
}

.leaflet-control-zoom-out {
    background-color: white !important;
    color: #b9b9b9 !important;
    padding: 6.5px !important;
}

.leaflet-touch .leaflet-control-layers-toggle {
    width: 43px;
    height: 43px;
}

.leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}